//@ts-check
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Confirm, ModalConstants } from "components/OnlineModal";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import WaitRipple from "components/WaitRipple";
import ScrollContent from "components/ScrollContent";
import useTypedSelector from "utils/useTypedSelector";

import { classMailDuck } from "./ClassMailDuck";
import ClassMailDetail from "./ClassMailDetail";
import ClassMailForm from "./ClassMailForm";
import MailHeaders from "components/Mail/MailHeaders";

import classes from './classmail.module.scss';

const ClassMail = () => {

    const dispatch = useDispatch();
    const mailLists = useTypedSelector(state => state.classMail.mailLists);
    const currentProject = useTypedSelector(state => state.classMail.currentProject);
    const classData = useTypedSelector(state => state.classData);
    const { type: route, payload: {  listtype } } = useTypedSelector(state => state.location);
    const {  languageService: t } = useI18n();

    // clean up at unload of component
    useEffect(() => {

        return () => {
            dispatch(classMailDuck.setStartData(null));
        };
    }, [dispatch])

    const closeForm = () => {
        dispatch({ type: "CLASS_MAIL", payload: { classid: classData.currentClass.Id, listtype: listtype} });

    }

    /**
     * @param {number} id
     */
    const handleDelete = async (id) => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("delete_general_confirm")}</div>),
            languageService: t,
            okOnEnter: true,
            type: "yes/no"

        });

        if (result === ModalConstants.Yes) {

            // First supress any warnings if dirty data
            dispatch(classMailDuck.setDirty(false));
            // then delete
            dispatch(classMailDuck.deleteMail(id));
            // then close form
            closeForm();
        };
    }

    const newMail = async () => {
        const data = {
            MailSubject: t.getText('mail.new'),

            ClassId: classData.currentClass.Id,
            GroupId: null
        }
        const _mailid = await classMailDuck.newMail(data, dispatch);

        // then navigate to edit:
        dispatch({ type: "CLASS_MAIL_EDIT", payload: { classid: classData.currentClass.Id, mailid: _mailid, listtype: 'draft' } });

    }

    const handleClick = (id) => {
        dispatch({ type: "CLASS_MAIL_EDIT", payload: { classid: classData.currentClass.Id, mailid: id, listtype: listtype } });
    }


    const changeTab = (type)=>{
        dispatch({ type: "CLASS_MAIL", payload: { classid: classData.currentClass.Id, listtype: type } });
    }

    const handleMailSent = (mailid)=>{
        dispatch({ type: "CLASS_MAIL_EDIT", payload: { classid: classData.currentClass.Id, mailid: mailid, listtype: 'sent' } });
    }

    if (mailLists === null) {
        return <ScrollContent scrollname="class-mail"><WaitRipple /></ScrollContent>
    }


    if (route === "CLASS_MAIL" || route === "CLASS_MAIL_EDIT") {

        if (mailLists === null) {
            return <ScrollContent scrollname="class-mail"><WaitRipple /></ScrollContent>
        }

        return (
            <ScrollContent scrollname="class-mail">
                <div className="card-box big-card-box">
                    <div className="card-header ln-nostyle d-flex justify-content-between">
                        <h3>
                            <LnIcon className="header-icon" name="email-icon" />
                            {t.getText('send.mail.to', classData.currentClass.Name)}
                        </h3>
                        <button type="button" className="btn btn-primary btn-small" onClick={() => newMail()}>{t.getText("mail.bulk.new")}</button>
                    </div>
                    <div className="card-body pt-5">
                       <div className="row">
                            <div className={["", (route === "CLASS_MAIL_EDIT" ? 'd-none  d-md-block col-sm-12 col-md-4 col-lg-4 col-xl-3' : 'col-sm-12 col-md-4 col-lg-4 col-xl-3')].join(' ')}>
                                <MailHeaders listtype={listtype} changeTab={(tab)=>changeTab(tab)} handleClick={handleClick} handleDelete={handleDelete}  currentProject={currentProject} mailLists={mailLists} />
                            </div>

                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9">
                                {(route !== "CLASS_MAIL_EDIT") && <div className={classes['email-bulk-explain']}>
                                <p dangerouslySetInnerHTML={{ __html: t.getText('email.bulk.explain.html') }}></p>
                                </div>}
                                {
                                    (route === "CLASS_MAIL_EDIT") && !currentProject && <WaitRipple />}
                                {
                                    (route === "CLASS_MAIL_EDIT") && currentProject && currentProject.Queued && <ClassMailDetail mailData={currentProject} closeForm={closeForm} handleDelete={handleDelete} />}
                                {
                                    (route === "CLASS_MAIL_EDIT") && currentProject && !currentProject.Queued && <ClassMailForm handleMailSent={handleMailSent} mailData={currentProject} classData={classData} closeForm={closeForm} handleDelete={handleDelete} />}
                            </div>
                        </div>

                    </div>
                </div>
            </ScrollContent>)
    }

}


export default ClassMail;
