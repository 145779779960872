// @ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import Donut from '../Donut/Donut';

/**
 * ResultsGraphic
 * @return  charts for results and lecture views.
 * @param {{
    listingStyle: string,
    chartStyle: string,
    classData: any,
    showLectures: boolean,
    showStatusTexts: boolean,
    testText?: any,
    lecturesText?: any 

}} props
 */
const ResultsGraphic = (props) => {


    const { languageService: t } = useI18n();
    const classData = props.classData;



    const percentLecturesViewed = classData.PercentLecturesViewed;
    const percentLecturesRemaining = 100 - percentLecturesViewed;

    const testResultCountsTotal = classData.TestResultCounts.Total;
    const testResultCountsSubmitted = classData.TestResultCounts.Submitted;
    const testResultCountsApproved = classData.TestResultCounts.Approved;
    const testResultCountsFailed = classData.TestResultCounts.Failed;
    const testResultCountsToFollowUp = classData.TestResultCounts.ToFollowUp;
    const totalTestsDone = testResultCountsSubmitted + testResultCountsApproved + testResultCountsFailed + testResultCountsToFollowUp;
    const remainingTestCount = testResultCountsTotal - totalTestsDone;


    const donutLectData = [{
        value: percentLecturesViewed,
        cssClass: "donut-approved-stroke"
    },
    {
        value: percentLecturesRemaining,
        cssClass: "donut-notset-stroke"
    }
    ]



    let donutTestData = [];
    if (testResultCountsApproved) {
        donutTestData.push(
            {
                value: testResultCountsApproved,
                cssClass: "donut-approved-stroke"
            })
    };

    if (testResultCountsFailed) {
        donutTestData.push(
            {
                value: testResultCountsFailed,
                cssClass: "donut-failed-stroke"
            }
        );
    }

    if (testResultCountsSubmitted) {
        donutTestData.push(
            {
                value: testResultCountsSubmitted,
                cssClass: "donut-passed-stroke"
            }
        );
    }

    donutTestData.push(
        {
            value: remainingTestCount + testResultCountsToFollowUp,
            cssClass: "donut-notset-stroke"
        });





    return (
        <ul className=" list-group list-group-flush list-group-horizontal">
            {props.showLectures &&
                <li className={[' d-flex flex-column align-items-center', props.listingStyle].join(' ')}>
                    <div className={props.chartStyle + " position-relative"}>
                        <Donut thickness={25} gap={2} segments={donutLectData} centerText={classData.PercentLecturesViewed + "%"} />
                    </div>
                    {props.lecturesText ? props.lecturesText
                        :
                        props.showStatusTexts && <div className="text-center">{t.getText('view.full.watched')}</div>
                    }
                </li>
            }

            <li className={[' d-flex flex-column align-items-center', props.listingStyle].join(' ')}>
                <div className={props.chartStyle + " position-relative"}>
                    <Donut thickness={25} gap={2} segments={donutTestData} centerText={(testResultCountsApproved + testResultCountsSubmitted + testResultCountsToFollowUp) + "/" + testResultCountsTotal} />
                    {props.showStatusTexts && <div className="text-center">{t.getText('progress.test')}</div>}

                </div>

            </li>
            <li className="small d-flex">
                {props.testText ? props.testText
                    :
                    props.showStatusTexts &&
                    <div className="mb-4">
                        <div className="border-list">{t.getText('approvedbyteacher')} </div>
                        <div className="border-list pink-border-list">{t.getText('failed')} </div>
                        <div className="border-list yellow-border-list">{t.getText('passed')} </div>
                    </div>
                }
            </li>
        </ul>
    )
}

export default ResultsGraphic;