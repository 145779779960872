//@ts-check
import React, { useRef, useEffect, useCallback } from "react";
import { useStore } from "react-redux";
import { findDOMNode } from 'react-dom'
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import LnIcon from "components/LnIcon";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import LectureControls from "./LectureControls";
import Screen from "./Screen";
import classes from './lecture.module.scss';
import lecturePlayerService from "./LecturePlayerService";
import screenfull from "screenfull";
import browserService from "utils/BrowserService"
import ForumThreadInLectureDialog from "./ForumThreadInLectureDialog";
import TestInLectureDialog from "./TestInLectureDialog";

const LecturePlayer = (props) => {

    const dispatch = useDispatch();
    const { showTestDialog, showForumDialog, currentLecture, controlsState: { visible: controlsVisible, fullscreen }, showScreensList, currentScreenData } = useTypedSelector(state => state.lecture);
    //const location =  useTypedSelector(state => state.location.pathname);
    const lectureElement = useRef(null);
    const store = useStore()
    const canFullScreen = browserService.canSetFullScreen();
    const { languageService: t } = useI18n();

    useEffect(() => {
        lecturePlayerService.Init();


        return () => {
            lecturePlayerService.Init();
            dispatch(lectureDuck.setFullScreen(false));
            if (screenfull && canFullScreen) {
                // @ts-ignore
                screenfull.off("change", screenFullChange);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    /** 
     * clean up when leaving the lecture
     */
    useEffect(() => {
        return () => {
            if (dispatch != null) {
                dispatch(lectureDuck.setCurrentLecture(null));
            }
        }
    }, [dispatch])


    useEffect(() => {
        if (canFullScreen === true) {
            return;
        }

        dispatch(lectureDuck.setFullScreen(true));
        dispatch(lectureDuck.setControlsVisible(false));

    }, [currentScreenData, canFullScreen, dispatch]);


    const setControlsVisible = (e, state) => {
        dispatch(lectureDuck.setControlsVisible(state));
    }

    // clean up on exit if we have a listener
    useEffect(() => {
        dispatch(lectureDuck.setWantedPlayingState(true));
        // eslint-disable-next-line
    }, []);



    const toggleFullScreen = (ev) => {

        ev.currentTarget.blur();

        if (canFullScreen) {
            if (screenfull.isEnabled) {

                if (screenfull.isFullscreen) {
                    screenfull.exit();
                }
                else {
                    // @ts-ignore
                    screenfull.request(findDOMNode(lectureElement.current));
                    lecturePlayerService.SetFullScreenControls();
                }
            }
        }

    }


    const screenFullChange = useCallback(() => {
        if (canFullScreen) {
            // @ts-ignore
            if (!screenfull.isFullscreen) {
                lecturePlayerService.UnsetFullScreenControls();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (screenfull && canFullScreen) {
        // @ts-ignore
        screenfull.on("change", screenFullChange);
    }


    /**
   * 
   * @param {KeyboardEvent} e 
   */
    const handleKeyDown = (e) => {
        if (e.key && e.key.toLowerCase) {
            handleKeyEvent(e.key.toLowerCase());
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        }
        // eslint-disable-next-line
    }, [])



    const handleKeyEvent = useCallback((key) => {

        //const key = event.which || event.keyCode;


        switch (key) {
            case "arrowleft":
                lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.prev, null);
                break;
            case "arrowright":
                lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.next, null);
                break;

            case "escape":
                if (!showScreensList) {
                    lecturePlayerService.QuitLecture();
                }
                break;

            case " ":
                dispatch(lectureDuck.togglePlayingState());
                break;
            default:
                break;
        }
    }, [showScreensList, dispatch, store]);

    if (!currentLecture) {
        return <WaitRipple />
    }

    return (
        <div className={[classes.lecture, fullscreen ? classes.fullScreen : ""].join(" ")} ref={lectureElement} >

            <Screen className={classes.screen + " pointer"} />
            <LectureControls lectureContainer={lectureElement} toggleFullScreen={toggleFullScreen} >
                {!canFullScreen &&
                    <button title={t.getText("hide")} className={classes.hideControlsButton + "  btn btn-link "} onClick={(e) => setControlsVisible(e, false)}>
                        <LnIcon name="close" className="ml-3" />
                    </button>
                }
            </LectureControls>

            {!controlsVisible && !canFullScreen &&
                <button title={t.getText("show")} className={classes.showControlsButton + " " + classes.roundButton + " btn btn-link d-flex align-items-center"} onClick={(e) => setControlsVisible(e, true)}>
                    <LnIcon className="" name="show-controls" />
                </button>

            }

            {showForumDialog && <ForumThreadInLectureDialog container={lectureElement} />}
            {showTestDialog && <TestInLectureDialog container={lectureElement} />}


        </div >

    )
}


export default LecturePlayer;