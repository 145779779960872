//@ts-check
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { anonPaths } from "AppConstants"
import { LOGO } from "assets/images";
import Loader from "./components/loader";
import useTypedSelector from "utils/useTypedSelector";
import LoginComponent from "./features/login/LoginComponent";
import bootstrap from "./utils/bootstrapper";
import StudentRoot from "./features/classes/StudentRoot";
import GeneralRoot from "./features/General/GeneralRoot";
import CdnCookiePinger from "components/CdnCookiePinger";
import SearchForm from "./features/search/SearchForm";
import NotFound from "features/layout/NotFound";
import Root from "features/Root";
import SetNewPassword from "features/login/SetNewPassword";
import AdminInfo from "features/admin/AdminInfo";
import SideBar from "features/layout/SideBar";
import SideBarMenu from "features/classes/SideBarMenu/SideBarMenu";
import ContentWrapper from "features/layout/ContentWrapper";
import SiteHeader from "features/layout/SiteHeader";
import ErrorHandlerComponent from "components/ErrorHandler/ErrorHandlerComponent";
import NetworkReconnect from "features/network/NetworkReconnect";
import VerifyEmail from "features/login/VerifyEmail";
import Register from "features/login/Register";
import ReLogin from "features/login/ReLogin";


const App = props => {
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();

    const languages = useTypedSelector(state => state.text.languages);
    const user = useTypedSelector(state => state.login.user);
    const route = useTypedSelector(state => state.location.type);

    const phrases = ["WMD: Weapons of Mass Debugging", 
        "I'm not a great programmer; I'm just a good programmer with great habits.", 
        "The best error message is the one that never shows up.", 
        "The best thing about a boolean is even if you are wrong, you are only off by a bit.", 
        "Programming isn't about what you know; it's about what you can figure out.",
        "Code is like humor. When you have to explain it, it’s bad.",
        "Deleted code is debugged code.",
        "Simplicity is the soul of efficiency.",
        "Fix the cause, not the symptom.",
        "Experience is the name everyone gives to their mistakes.",
        "In order to be irreplaceable, one must always be different.",
        "Java is to JavaScript what car is to Carpet.",
        "Knowledge is power.",
        "Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.",
        "Perfection is achieved not when there is nothing more to add, but rather when there is nothing more to take away.",
        "Code never lies, comments sometimes do.",
        "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
        "Optimism is an occupational hazard of programming: feedback is the treatment.",
        "When to use iterative development? You should use iterative development only on projects that you want to succeed.",
        "The most important property of a program is whether it accomplishes the intention of its user.",
        "The best way to predict the future is to invent it.",
        "Teaching is the one profession that creates all other professions.",
        "The art of teaching is the art of assisting discovery.",
        "Education is not the filling of a pail, but the lighting of a fire.",
        "Teaching is the greatest act of optimism.",
        "To teach is to learn twice.",
        "The influence of a good teacher can never be erased.",
        "A teacher affects eternity; he can never tell where his influence stops.",
        "The mediocre teacher tells. The good teacher explains. The superior teacher demonstrates. The great teacher inspires.",
        "It is the supreme art of the teacher to awaken joy in creative expression and knowledge.",
        "A good teacher is like a candle - it consumes itself to light the way for others.",
        "The dream begins with a teacher who believes in you, who tugs and pushes and leads you to the next plateau, sometimes poking you with a sharp stick called 'truth'.",
        "One child, one teacher, one book, one pen can change the world.",
        "Good teaching is more a giving of right questions than a giving of right answers.",
        "The best teachers teach from the heart, not from the book.",
        "Teaching is not a lost art, but the regard for it is a lost tradition.",
        "The greatest sign of success for a teacher is to be able to say, 'The children are now working as if I did not exist.'",
        "Teaching is the profession that teaches all the other professions.",
        "A good teacher can inspire hope, ignite the imagination, and instill a love of learning.",
        "The best teachers are those who show you where to look but don't tell you what to see.",
        "Teaching kids to count is fine, but teaching them what counts is best.",
        "The beautiful thing about learning is that no one can take it away from you.",
        "Education is the passport to the future, for tomorrow belongs to those who prepare for it today."
    ];
        const phrase = phrases[Math.floor(Math.random() * phrases.length)];

    useEffect(() => {

        console.log( `%c © LäraNära  ${new Date().getFullYear()}`, 'font-size: 20px;color: #5faa45;');
        console.log( `%c Excellence since year 2000`, 'font-size: 20px;color: #5faa45;');
        console.log("");
        console.log(phrase);
        console.log("");
        console.log("⚠️ This place is just for developers. Never put any commands here even if promted by others");
        console.log("");
        console.log("Enjoy teaching. Enjoy your new knowledge");
        

        console.log( `%c ${owl}`, 'font-family: courier,monospace;'  );

        if (process.env.NODE_ENV !== "production") {
            window.document.title = "DEV " + window.document.title;
        }

        let routeToLoginIfNoUser = !anonPaths.find( p => route.indexOf(p) > -1);


        if (ready === true) {
            return;
        }
        async function bootUp() {


            const lang = languages
                ? languages.find(l => l.IsCurrent === true && l.Short.indexOf("x") < 0)
                : null;
            await bootstrap(dispatch, lang, user, routeToLoginIfNoUser);
            setReady(true);
        }

        bootUp();



        // eslint-disable-next-line 
    }, []);

    let rootPath = null;
    let standAlone = false;

    if (route.indexOf("ROOT") > -1) {
        rootPath = <Root />
        standAlone = true;
    }

    if (route.indexOf("CLASS") > -1) {
        rootPath = <StudentRoot />
    }

    if (route.indexOf("NOT_FOUND") > -1) {
        rootPath = <NotFound />
        standAlone = true;
    }
    
    if (route === "LOGIN") {
        rootPath = <LoginComponent />
        standAlone = true;
    }

    if (route === "LOGIN_NEW_PASSWD") {
        rootPath = <SetNewPassword />
        standAlone = true;
    }

    if (route === "LOGIN_VERIFY_EMAIL") {
        rootPath = <VerifyEmail />
        standAlone = true;
    }

    if (route === "LOGIN_REGISTER") {
        rootPath = <Register />
        standAlone = true;
    }

    if (route === "LOGIN_REGISTER_DATAPOLICY") {
        rootPath = <Register />
        standAlone = true;
    }

    if (route.indexOf("GENERAL_") > -1) {
        rootPath = <GeneralRoot />
    }

    if (route.indexOf("ADMIN") > -1) {
        rootPath = <AdminInfo />
    }


    if (ready === false) {
        return <Loader />;
    } else {

        if (!user || standAlone) {
            return rootPath;
        }

        return (
            <>
                <ErrorHandlerComponent />

                <SideBar logo={LOGO}>
                    <SideBarMenu />
                </SideBar>

                <ContentWrapper>
                    <SiteHeader />
                    {rootPath}
                </ContentWrapper>

                <CdnCookiePinger />

                <SearchForm />
                <NetworkReconnect />
                
                <ReLogin />
            </>
        );
    }
};

const owl = `
          __________-------____                 ____-------__________
          \\------____-------___--__---------__--___-------____------/
           \\//////// / / / / / \\   _-------_   / \\ \\ \\ \\ \\ \\\\\\\\\\\\\\\\/
             \\////-/-/------/_/_| /___   ___\\ |_\\_\\------\\-\\-\\\\\\\\/
               --//// / /  /  //|| (O)\\ /(O) ||\\\\  \\  \\ \\ \\\\\\\\--
                    ---__/  // /| \\_  /V\\  _/ |\\ \\\\  \\__---
                         -//  / /\\_ ------- _/\\ \\  \\\\-
                           \\_/_/ /\\---------/\\ \\_\\_/
                               ----\\   |   /----
                                    | -|- |
                                   /   |   \\
                                   ---- \\___|
`;


export default App;
