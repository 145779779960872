//@ts-check
import React, { useEffect, useState } from "react";
import WaitRipple from "components/WaitRipple";
import { useI18n } from "components/lni18n";

/**
 * @param {{
    sessionData: import('../User').UserLoggedInDto
}} props
 */
const AsTable = ({ sessionData }) => {

    const { languageService: t } = useI18n();
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        if (!sessionData) return;
        let totalDuration = 0;
        const data = sessionData.Days.map(d => {

            let durationMilli = 0
            if (d.Sessions.length > 0) {
                durationMilli = d.Sessions.reduce((acc, s) => acc + (s.End.getTime() - s.Start.getTime()), 0);
            }

            let duration = null;
            if (durationMilli > 0) {
                duration = t.gethhmm(durationMilli/1000);
                totalDuration += durationMilli;
            }
            return { date: t.getShortDateString(d.Day), duration: duration };
        });

        setPageData({ tableData: data, totalDuration: t.gethhmm(totalDuration/1000) });


    }, [sessionData, t]);


    if (!sessionData || !pageData) {
        return <WaitRipple />
    }

    return (

        <div className="row mb-4">
            <div className="col-md-4 col-sm-12">
                <h4 className="mb-5">
                    {t.getText("aggregate")} (hh:mm) {pageData.totalDuration}
                </h4>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="">{t.getText("date")}</th>
                            <th className="">{t.getText("duration")} (hh:mm)</th>

                        </tr>
                    </thead>
                    <tbody>
                        {pageData.tableData.map((d) => (
                            <tr key={String(d.date)}>
                                <td >
                                    {d.date}
                                </td>
                                <td >
                                    {d.duration}
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div>
        </div>

    )

}


export default AsTable;