//@ts-check

export const APP_URL = process.env.NODE_ENV === "production" ? process.env.APP_URL : process.env.APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_HOST = process.env.REACT_APP_API_URL.replace(/\/api$/, "");


export const anonPaths = [ "LOGIN", "LOGIN_NEW_PASSWD","LOGIN_REGISTER", "LOGIN_VERIFY_EMAIL"];

export const browserLanguage = navigator["userLanguage"]
  || (navigator.languages && navigator.languages.length && navigator.languages[0])
  || navigator.language
  || navigator["browserLanguage"]
  || navigator["systemLanguage"] || 'en';

export const GradeJudgement = {
  NotSet: 0,
  Failed: 1,
  Approved: 2,
  ApprovedPlus: 3
}

let _release = null;
const meta = document.querySelector('meta[name="portal-release"]');
if (meta) {
  _release = meta["content"];
}

if (_release === "%VERSION%") {  // localhost
  _release = null;
}
export const release = _release;


/**
 * @type {number}
 */
export const MAIL_POLL_INTERVAL_IN_SEC = 10;

// time to keep cached part in redux before new fetch at server
export const MINUTES_PART_CACHE = 5;

export const flagUrls = {
  "da-dk": '/imgs/flag_da.svg',
  "en-gb": '/imgs/flag_en.svg',
  "sv-se": '/imgs/flag_sv.svg'
}


export const endPoint = {
  API_URL: API_URL,
  API_HOST: API_HOST,
  LOGIN_URL: '/login/login',
  LOGOUT_URL: '/login/logout',
  LANGUAGES_URL: '/languages',
  SET_LANG_URL: '/language',
  PING_URL: '/login/ping',

  GET_CONFIGDATA_URL: '/configs',

  GET_UNIT_IMAGE_URL: (unitId)=>  `${API_URL}/unit/${unitId}/image`,

  LOGIN_DATA_URL: (lang) => `/login/logindata?langid=${lang}`,
  LOGIN_WELCOME_SCREEN: '/login/logindata?langid=',
  FORGOT_PASSWORD_URL: '/login/resetpassword',
  CHANGE_PASSWORD: '/login/ChangePasswd',
  GET_TEXT_DICTIONARY: (lang) => `/languages/strings/${lang}`,


  /* TEACHER */
  TEACHER_DASHBOARD_URL: (date) => `/dashboard/?start=${date}`,
  TEACHER_NAVBAR_URL: `/navbar`,
  TEACHER_ALL_CLASSES_URL: () => `/class/teacherclasses`,
  TEACHER_CLASS_DATA_URL: ({classid, dateString}) => `/dashboard/class/${classid}/teacher?lastVisit=${dateString}`,

  /* SEARCH */
  SEARCH_URL: (text, page) => `/search/?text=${text}&page=${page}`,


  /* CLASS */
  ALL_CLASSES_STUDENT_URL: '/class/GetClassesForStudent',

  DASHBOARD_AND_CLASS_URL: (classid, date) => `/class/${classid}/alldata?start=${date}`,
  CLASS_INFO_URL: (classid) => `/class/${classid}/info`,
  RESEND_VERFICATION_CODE_URL: `/class/resendcode`,
  VERFY_CODE_URL: `/class/VerifyCode/`,

  GET_PART_URL: (classId, partId) => `/class/${classId}/coursepart/${partId}`,
  CLASS_OVERVIEW_URL: () => `/class/overview`,
  GET_CLASS_CERTIFICATE_URL: (userId, classId) => `/diploma/${userId}/${classId}/get`,

  /* CLASS  TEACHER*/
  CLASS_ACTIVITY_URL: (classid) => `/class/${classid}/fullprogress`,
  CLASS_WATCHED_URL: (classid) => `/class/${classid}/watched`,
  CLASS_OPENED_LINKS_URL: (classid) => `/class/${classid}/watched/links`,
  CLASS_OPENED_FILES_URL: (classid) => `/class/${classid}/watched/files`,

  /* CLASS  NOTIFICATIONS*/
  CLASS_NOTIFICATIONS_URL: (classid) => `/class/${classid}/notification/list`,
  CLASS_NOTIFICATIONS_EDIT_URL: (classid) => `/class/${classid}/notification/list/edit`,
  CLASS_NOTIFICATION_EDIT_URL: (classid, notificationId) => `/class/${classid}/notification/${notificationId}`,
  CLASS_NOTIFICATION_NEW_URL: (classid) => `/class/${classid}/notification/create`,
  CLASS_NOTIFICATION_DELETE_URL: (classid, id) => `/class/${classid}/notification/${id}`, // use DELETE

  /* TIMETABLE */
  CLASS_START_TIMETABLE_URL: (classid) => `/class/${classid}/timetable`,
  CLASS_EDIT_EVENT_DATA_URL: (classid) => `/class/${classid}/timetable/editor`,
  CLASS_TIMETABLE_GET_EVENTS_CLASS_ONLY_URL: (classid, start, end, teacherOnly) => `/class/${classid}/timetable/events/fc?start=${start}&end=${end}${ teacherOnly ? "&teacherOnly=true": ""}`,
  CLASS_TIMETABLE_GET_EVENTS_OTHER_CLASSES_URL: (classid, start, end, teacherOnly) => `/class/${classid}/timetable/events/fc/exclude?start=${start}&end=${end}${ teacherOnly ? "&teacherOnly=true": ""}`,
  CLASS_TIMETABLE_GET_EVENTS_UNIT_CLASSES_URL: (classid, start, end ) => `/class/${classid}/timetable/events/allforunit?start=${start}&end=${end}`,
  CLASS_TIMETABLE_GET_EVENTS_SELECTED_CLASSES_URL: (classid, start, end ) => `/class/${classid}/timetable/events/forClasses?start=${start}&end=${end}`,

  TIMETABLE_GET_EVENT_DETAILS_URL: (id) => `/event/${id}`,
  TIMETABLE_DELETE_EVENT_URL: (id) => `/event/${id}`,
  TIMETABLE_GET_CHECK_EVENT_URL: `/event/check`,
  TIMETABLE_GET_POST_EVENT_URL: `/event`,
  TIMETABLE_GET_USER_DEFAULT_TIMES_URL: `/timetable/DefaultTimes`,

  /* FEED */
  // filterType: ByPosts=0 | ByComments=1   userId?: string
  CLASS_FEED_START_URL: (classId, userId, filterType) => `/class/${classId}/feed?filtertype=${filterType}&filterByUser=${userId}`,
  CLASS_MORE_FEED_URL: (feedId, postId, userId, filterType) => `/feed/${feedId}?after=${postId}&filterByUser=${userId}}&filterType=${filterType}`,
  CLASS_CREATE_FEED_URL: (feedId) => `/feed/${feedId}/post`,
  CLASS_UPDATE_FEED_URL: (feedId, postId) => `/feed/${feedId}/post/${postId}`,
  CLASS_CREATE_FEED_COMMENT_URL: (feedId, postId) => `/feed/${feedId}/post/${postId}/comment`,
  CLASS_UPDATE_FEED_COMMENT_URL: ( commentId,  feedId, postId) => `/feed/${feedId}/post/${postId}/comment/${commentId}`,
  CLASS_GET_ONE_FEEDPOST_URL: (feedId, postId) => `/feed/${feedId}/post/${postId}`,
  CLASS_GET_ONE_FEEDCOMMENT_URL: (feedId, postId, commentId) => `/feed/${feedId}/post/${postId}/comment/${commentId}`,
  FEED__ALL_COMMENTS_URL: (feedId, postId) => `/feed/${feedId}/post/${postId}/comment`,

  /* FORUM */
  CLASS_FORUM_START_URL: (classId, userId, filterType) => `/class/${classId}/forum?filtertype=${filterType}&filterByUser=${userId}`,
  CLASS_CUEPOINT_FORUM_START_URL: (classId, threadId,) => `/class/${classId}/${threadId}/forumForCuePoint`,
  /**
  //* @param {{forumId:string, page:number, sortBy:number, desc:boolean, userId: null | string, filterType: null | 0 | 1}}
  */
  MORE_FORUM_URL: (forumId, page, sortBy, desc, userId, filterType) => `/forum/${forumId}?page=${page}&by=${sortBy}&desc=${desc}&filtertype=${filterType}&filterByUser=${userId}`,
  GET_THREAD_URL: (forumid, threadid) => `/forum/${forumid}/thread/${threadid}`,
  GET_CREATE_THREAD_URL: (forumid) => `/forum/${forumid}/thread`,
  GET_UPDATE_POST_URL: (id) => `/forum/post/${id}/update`,
  GET_FORUM_READERS_URL: (forumid, threadid) => `/forum/${forumid}/thread/${threadid}/readBy`,
  GET_FORUM_PUBLISH_URL: (forumid, threadid) => `/forum/${forumid}/thread/${threadid}/publish`,
  GET_FORUM_NEW_POSTS_URL: (forumid, epoch) => `/forum/${forumid}/${epoch}/newheaders`,
  GET_SET_THREAD_AS_READ_URL: (forumid) => `/forum/${forumid}/setAllAsRead`,
  GET_SET_FORUM_SUBSCRIPTION: (forumid, sendTo) => `/forum/${forumid}/${sendTo}/subscribe`,
  GET_DELETE_FORUM_SUBSCRIPTION: (forumid) => `/forum/${forumid}/unsubscribe`,
  GET_TEST_FOR_CONNECTION_URL: (forumid) => `/forum/${forumid}/thread/TestsForConnection`,

  /*  FEEDBACK */
  GET_POST_FEEDBACK_URL: () => `/feedback`,
  GET_USER_FEEDBACK_URL: () => `/feedback/UserFeedbacks`,

  /*  EMAIL TO CLASS */
  GET_CREATE_MAIL_FOR_CLASS_URL: (classid) => `/mail/bulk`,
  GET_MAIL_LIST_FOR_CLASS_URL: (classid) => `/class/${classid}/mail`,
  GET_MAIL_FOR_CLASS_URL: (classid, projid) => `/class/${classid}/mail/${projid}`,
  GET_SAVE_MAIL_FOR_CLASS_URL: (projid) => `mail/bulk/${projid}`,
  GET_DELETE_MAIL_FOR_CLASS_URL: (projid) => `mail/bulk/${projid}`,
  GET_SEND_MAIL_FOR_CLASS_URL: (projid) => `mail/bulk/${projid}/send`,
  GET_RESEND_MAIL_FOR_CLASS_URL: (projid) => `mail/bulk/${projid}/resend`,
  GET_ADD_MAIL_ATTACHMENT_FOR_CLASS_MAIL_URL: (projid) => `mail/bulk/${projid}/attachment`,
  GET_DELETE_MAIL_ATTACHMENT_FOR_CLASS_MAIL_URL: (projid, attachmentid) => `mail/bulk/${projid}/attachment/${attachmentid}`,
  GET_STATUS_FOR_CLASS_MAIL_URL: (projid) => `/mail/bulk/${projid}/status`,

  /* SUBGROUP */
  GET_MEMBERS_FOR_BASEGROUP_URL: (classId, groupId) => `/class/${classId}/group/${groupId}/members`,
  GET_FEED_FOR_BASEGROUP_URL: (classId, groupId, userId, filterType) => `/class/${classId}/subgroup/${groupId}/feed?filtertype=${filterType}&filterByUser=${userId}`,
  GET_FORUM_FOR_BASEGROUP_URL: (classId, groupId, userId, filterType) => `/class/${classId}/subgroup/${groupId}/forum?filtertype=${filterType}&filterByUser=${userId}`,
  GET_MAIL_LIST_FOR_BASEGROUP_URL: (classid, groupid) => `/class/${classid}/group/${groupid}/mail`,
  CREATE_NEW_MAIL_FOR_BASEGROUP_URL: (classid, groupid) => `/class/${classid}/group/${groupid}/mail`,
  // GET_CREATE_MAIL_FOR_CLASS_URL: (classid) => `/mail/bulk`,


  /* TEST RESULTS */
  GET_TEST_RESULTS_URL: (classId) => `/admin/class/${classId}/results`,
  GET_RESULT_EDIT_URL: ({resultId, classId}) => `/admin/class/${classId}/results/${resultId}`,
  GET_TEST_RESULTS_BY_TEST_URL: (classId, testId) => `/admin/class/${classId}/results/${testId}/test`,
  GET_TEST_RESULTS_AS_EXCEL_URL: (classId) => `${API_URL}/admin/class/${classId}/resultsXL`,


  /* SINGLE MAIL */

  GET_STATUS_FOR_SINGLE_MAIL_URL: "/mail/single/status",
  GET_SINGLE_MAIL_LIST_URL: "/mail/single",
  GET_CREATE_SINGLE_MAIL_URL: "/mail/single",
  GET_SINGLE_MAIL_URL: (projid) => `/mail/single/${projid}`,
  GET_SAVE_SINGLE_MAIL_URL: (projid) => `/mail/single/${projid}`,
  GET_SEND_SINGLE_MAIL_URL: (projid) => `/mail/single/${projid}/send`,
  GET_DELETE_SINGLE_MAIL_URL: (projid) => `/mail/single/${projid}`,
  GET_ADD_MAIL_ATTACHMENT_FOR_SINGLE_MAIL_URL: (projid) => `/mail/single/${projid}/attachment`,
  GET_DELETE_MAIL_ATTACHMENT_FOR_SINGLE_MAIL_URL: (projid, attachmentId) => `/mail/single/${projid}/attachment/${attachmentId}`,
  GET_RECEIVERS_URL: (string) => `/user/find?text=${string}`,


  /*  DOCBOX ********************************** */

  GET_DOCBOX_FOR_CLASS_URL: (classid) => `/docbox/${classid}/studentclassstudents`,
  GET_DOCBOX_FOR_CLASSTEACHER_URL: (classid) => `/docbox/${classid}/studentclassteachers`,
  DOCBOX_START_URL: `/docbox/start`,
  DOCBOX_CREATE_FOLDER_URL: `/docbox/CreateFolder`,
  DOCBOX_SAVE_UPDATE_FILE_URL: `/docbox/saveorupdatefile`,
  DOCBOX_TRASH_URL: `/docbox/trash`,
  DOCBOX_SHARED_TRASH_URL: `/docbox/sharedtrash`,

  GET_DOCBOX_USER_AND_GROUP_SEARCH_URL: (userid, type, groupScope, text) => `/docbox/list/${userid}/${type}/${groupScope}/usersandgroups?text=${text}`,
  DOCBOX_SAVE_UPDATE_CLASSFILE_URL: (classid, fileid) => `/class/${classid}/file/${fileid}`,


  /**
   * type 30 = file, 31 = folder
   * 
   * params {string} objectid  
   * params {30 | 31} type  
   */
  GET_DOCBOX_PERMISSIONS_URL: (objectid, type) => `/docbox/getPermissions?id=${objectid}&type=${type}`,
  DOCBOX_SET_PERMISSIONS_URL: `/docbox/setPermission`,
  DOCBOX_DELETE_PERMISSIONS_URL: `/docbox/deletepermission`,
  GET_DOCBOX_SHARED_FILE_DATA_URL: (fileid) => `/docbox/${fileid}/shares`,

  GET_DOCBOX_SHARED_URL: (scope) => `/docbox/${scope}/shared`,
  GET_DOCBOX_DELETEINFO_URL: (fileid) => `/docbox/${fileid}/DeleteFileInfo`,
  GET_DOCBOX_DOWNLOAD_URL: (fileid) => `/docbox/${fileid}/download`,

  GET_DOCBOX_MOVE_FILE_URL: (fileid) => `/docbox/${fileid}/movefile`,
  GET_DOCBOX_TRASH_FILE_URL: (fileid) => `/docbox/${fileid}/trashfile`,
  GET_DOCBOX_UNTRASH_FILE_URL: (fileid) => `/docbox/${fileid}/untrashfile`,
  GET_DOCBOX_DELETE_FILE_URL: (fileid) => `/docbox/${fileid}/deletefile`,

  GET_DOCBOX_RENAME_FOLDER_URL: (folderid) => `/docbox/${folderid}/RenameFolder`,
  GET_DOCBOX_TRASH_FOLDER_URL: (folderid) => `/docbox/${folderid}/trashfolder`,
  GET_DOCBOX_UNTRASH_FOLDER_URL: (folderid) => `/docbox/${folderid}/untrashfolder`,
  GET_DOCBOX_DELETE_FOLDER_URL: (folderid) => `/docbox/${folderid}/deletefolder`,

  GET_DOCBOX_EMPTY_TRASH_URL: (rootId) => `/docbox/${rootId}/EmptyTrash`,
  GET_DOCBOX_TRANSFER_TO_TEMP_URL: (fileId) => `/docbox/${fileId}/TransferToTemp`,

  /* IMAGE */
  GET_IMAGE_THUMB_URL: (imageid) => `${API_HOST}/image/get/${imageid}/thumbnail`,
  GET_IMAGE_URL: (imageid) => `${API_HOST}/image/get/${imageid}`,
  GET_IMAGE_PROXY_URL: (url) => `${API_HOST}/image/get/proxy?url=${ encodeURIComponent(url)}`,
  

  /* USER */
  USER_CURRENT_CLASSID_URL: `/user/currentclassid`,
  GET_USER_POLICY_TEXT_URL: (userid) => `/user/${userid}/GetPolicyText`,
  GET_USER_COOKIE_APPROVE_URL: (userid) => `/user/${userid}/SetCookieApproved`,
  GET_USER_POLICY_APPROVE_URL: (userid) => `/user/${userid}/SetPolicyApproved`,
  GET_USER_IMAGE_URL: (id) => `${API_URL}/user/${id}/image`,
  GET_USER_IMAGE_DELETE_URL: (id) => `${API_URL}/user/${id}/image/delete`,
  GET_USER_DATA_URL: (userid) => `/user/${userid}/detail`,
  GET_USER_SAVE_DATA_URL: (userid) => `/user/${userid}/info`,
  GET_USER_SAVE_PASSWORD_URL: (userid) => `/user/${userid}/password`,
  GET_USER_ISUNIQUE_URL: (propname, value, userid) => `/user/Property/${propname}/check?value=${encodeURIComponent(value)}&testThisUser=${userid}`,
  GET_USER_SESSIONS_URL: (userid, startdate, enddate) => `/user/${userid}/UserSessionStatistics?begin=${encodeURIComponent(startdate.toISOString())}&end=${encodeURIComponent(enddate.toISOString())}`,
  GET_USER_PROGRESS_URL: ( userid,classid) => `/user/${userid}/UserProgress${ classid ? "?classId=" + classid : "" }`,
  GET_USER_FIND_URL: (text, param, value) => `/user/find/?text=${text}&${param}=${value}`,
  GET_USER_SWITCH_URL: () => `/login/switch`,  // use w POST
  GET_USER_SWITCH_BACK_URL: () => `/login/switchback`,  // use w POST
  GET_USER_TEST_EMAIL_SETTINGS_URL: (userId, state) => `/user/${userId}/${state}/SetAllowTestResultEmails`,
  GET_USER_CONTENT_CHANGED_EMAIL_SETTINGS_URL: (userId, state) => `/user/${userId}/${state}/SetAllowCourseChangedEmails`,
  GET_USER_EMAIL_VERIFY_URL: (id, index) => `/user/${id}/detail/email/${index}/SendVerification`,
  GET_USER_VERIFY_EMAIL_NOW_URL: (id, index) => `/user/${id}/${index}/VerifyEmail`,
  GET_USER_TIMETABLESETTINGS_URL: "timetablesettings",

  GET_USER_NAME_PW_CHANGE: (userid, key, date,ticket) => `/login/GetUserName/${userid}/${key}/${date}/${ticket}`,
  GET_VERIFY_EMAIL_LINK: (signature, stamp, ticket) => `/mail/getverifyemaildata/${ticket}/${stamp}/${signature}`,
  PW_CHANGE_URL: "/login/ChangePasswd",

    // Register
  GET_SIGN_ON_DATA_URL: ({classid, formid}) => `/registration/GetRegistrationData/${classid}/${formid}`,
  GET_SEND_VERIFICATION_URL: ({email, langId, classId}) => `/registration/SendConfirmationCode?email=${email}&langId=${langId}&classId=${classId}`,
  POST_SIGN_ON_DATA_URL:  `/registration/RegisterUserToClass`,


  /* TESTS */
  GET_START_TEST_URL: (classId, testType, testId) => `/class/${classId}/test/${testType}/${testId}/start`,
  GET_TEST_STATUS_URL: (classId, testId) => `/class/${classId}/test/${testId}/status`,
  GET_TEST_TYPENAME_URL: (classId, testId) => `/class/${classId}/test/${testId}/type`,

  GET_SUBMIT_MCTEST_ANSW_URL: (classId, testId, session) => `/class/${classId}/test/multiplechoice/${testId}/answer/${session}`,
  GET_CHECK_MCTEST_ANSW_URL: (classId, testId, session) => `/class/${classId}/test/multiplechoice/${testId}/check/${session}`,
  GET_SUBMIT_MCTEST_URL: (classId, testId, session) => `/class/${classId}/test/multiplechoice/${testId}/submit/${session}`,
  GET_USER_LEFT_MCTEST_URL: (classId, testId, session) => `/class/${classId}/test/multiplechoice/${testId}/${session}/userleft`,
  GET_SUBMIT_QATEST_URL: (classId, testId) => `/class/${classId}/test/qa/${testId}/submit`,
  GET_SUBMIT_MQATEST_URL: (classId, testId) => `/class/${classId}/test/multiplequestion/${testId}/submit`,

  GET_POST_FILE_TO_TEST_URL: (classId, testId) => `/class/${classId}/test/fileupload/${testId}/AddFile`,
  GET_REMOVE_FILE_FROM_TEST_URL: (classId, testId) => `/class/${classId}/test/fileupload/${testId}/RemoveFile`,
  GET_SUBMIT_FILETEST_URL: (classId, testId) => `/class/${classId}/test/fileupload/${testId}/submitanswer`,

  GET_SUBMIT_CONFIRMTEST_URL: (classId, testId) => `/class/${classId}/test/simplyreply/${testId}/submit`,

  GET_SUBMIT_DRAWINGTEST_URL: (classId, testId) => `/class/${classId}/test/drawingtest/${testId}/submit`,
  GET_CHECK_DRAWINGTEST_URL: (classId, testId) => `/class/${classId}/test/drawingtest/${testId}/check`,

  GET_DELETE_TEST_RESULT_URL: (classId, resultId) => `/admin/class/${classId}/results/${resultId}`,

  /*   LECTURE */
  GET_LECTURE_DATA_URL: (classid, lectureid, height, width, density) => `/class/${classid}/lecture/${lectureid}/player?height=${height}&pixelDensity=${density}&width=${width}`,
  GET_LOG_SCREEN_URL: (screenid) => `/screen/${screenid}/view/`,
  GET_LOG_SCREEN_VIEWS_URL: (screenid) => `/screen/${screenid}/screenviews`,

  /* EVAL */
  SUBMIT_EVAL_URL: (classId, partId) => `/class/${classId}/coursepart/${partId}/eval`,

  /* SYSTEM CALLS */
  GET_S3_TOKEN_URL: `/s3fileupload/GetToken`,
  GET_CDN_COOKIE_URL: `/amazon/cloudfront/cookies/url`,

  /* GRAPH API */
  D3_LOCALE_FORMTS_URL: `/D3GraphApi/localeFormats`,
  GET_CLASS_LOGGED_IN_STAT_URL: (classId) => `${API_URL}/admin/class/${classId}/loggedInStat`

};