// @ts-check
import React, { useState } from 'react';
import LnIcon from "components/LnIcon";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import 'assets/scss/_datepicker.scss';
import { useI18n } from "components/lni18n";
import DateTimePicker from "./DateTimePicker";




/**
 * DateTimePicker
 * @module components/DateTimePicker/DateTimePicker
 * @return a Date selected by the user.
 * @param {{
    Date:  Date,
    DatePickerId?: string,
    onChange: Function,
    showIcon?: boolean,
    showMonthDropdown?: boolean,
    showYearDropdown?: boolean,
    showTimeSelect?: boolean,
    showWeekNumbers?: boolean,
    timeCaption?: string, // time
    timeFormat?: string, // HH:mm
    timeIntervals?: number, // {15}
    renderDays: 'all' | 'past' | 'future'
    buttonClassNames?: string,
    buttonText?: string

}} props
 */
const DateTimePickerDialog = (props) => {


    const initialDate = props.Date ? props.Date : new Date();
    const [dateValue, setDateValue] = useState(initialDate);
    const { languageService: t } = useI18n();
    const [show, setShowDialog] = useState(undefined);


    const closeAndSetDate = () => {
        setShowDialog(false);
        props.onChange(dateValue);
    }

    const onDateChanged = (d) => {
        setDateValue(d);
    }

    const buttonText = () => {
        if (props.buttonText) {
            return props.buttonText;
        }
        else {
            return (<>{props.showTimeSelect ? t.getShortDateTimeString(initialDate) : t.getShortDateString(initialDate)}
                {props.showIcon && <LnIcon className="mt-1 ml-3 icon-medium" name="datepicker-icon" />}
            </>)
        }
    }

    return (<>
        <button className={props.buttonClassNames ? props.buttonClassNames : "btn btn-link"} onClick={() => setShowDialog(true)} >
            {buttonText()}
        </button>

        <Modal backdrop="static" role="dialog" isOpen={show} toggle={() => setShowDialog(false)} size="lg">
            <ModalBody tabIndex={-1}>
                <DateTimePicker {...props} onDateChanged={onDateChanged} />
            </ModalBody>
            <ModalFooter className="d-flex">
                <Button className={'mr-auto btn btn-inverse  ml-2'} onClick={() => setShowDialog(false)}>{t.getText("cancel")}</Button>
                <Button className={'btn btn-primary ml-2'} onClick={closeAndSetDate}>{t.getText("ok")}</Button>

            </ModalFooter>
        </Modal>
    </>

    );

}

export default DateTimePickerDialog;

