import React from "react";
import { NavLink } from "redux-first-router-link";

const  makeMenu = (dependencies) => {
    const menuArr = [];


    const t = dependencies.languageService;

    if( !dependencies.currentClass || !dependencies.currentTheme || !dependencies.part){
        return [];
    }

    menuArr.push(<NavLink to={{
        type: 'CLASS_THEME_PART',
        payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "intro" }
    }}
        key="intro"
        isActive={(match, location) => location.payload.type === 'intro'}
        activeClassName={'active'}
    >
        {t.getText("introtext")}
            </NavLink>);


    if (dependencies.part.Lectures && dependencies.part.Lectures.length > 0) {
        menuArr.push(<NavLink to={{
            type: 'CLASS_THEME_PART',
            payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "lecture" }
        }}
            key="lect"
            isActive={(match, location) => location.payload.type === 'lecture'}
            activeClassName={'active'}
        >
            {t.getText("lectures")}
                </NavLink>);
    }

    if (dependencies.part.FileGroups && dependencies.part.FileGroups.length > 0) {
        menuArr.push(<NavLink to={{
            type: 'CLASS_THEME_PART',
            payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "file" }
        }}
            key="file"
            isActive={(match, location) => location.payload.type === 'file'}
            activeClassName={'active'}
        >
            {t.getText("files")}
                </NavLink>);
    }

    if (dependencies.part.LinkGroups && dependencies.part.LinkGroups.length > 0) {
        menuArr.push(<NavLink to={{
            type: 'CLASS_THEME_PART',
            payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "link" }
        }}
            key="link"
            isActive={(match, location) => location.payload.type === 'link'}
            activeClassName={'active'}
        >
            {t.getText("links")}
                </NavLink>);
    }

    if (dependencies.part.Tests && dependencies.part.Tests.length > 0) {
        menuArr.push(<NavLink to={{
            type: 'CLASS_THEME_PART',
            payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "test" }
        }}
            key="test"
            isActive={(match, location) => location.payload.type === 'test' || location.payload.type === 'runtest'}
            activeClassName={'active'}
        >
            {t.getText("tests")}
                </NavLink>);
    }

    if (dependencies.part.Eval && dependencies.part.Eval.Show && 
        (dependencies.part.Eval.Submitted || 
                (dependencies.part.Eval.Questions && dependencies.part.Eval.Questions.length > 0))) {
        menuArr.push(<NavLink to={{
            type: 'CLASS_THEME_PART',
            payload: { classid: dependencies.currentClass.Id, themeid: dependencies.currentTheme.Id, partid: dependencies.part.Id, type: "eval" }
        }}
            key="eval"
            isActive={(match, location) => location.payload.type === 'eval' }
            activeClassName={'active'}

        >
            {t.getText("evaluation")}
                </NavLink>);
    }

    

    return menuArr;
}

export default makeMenu;