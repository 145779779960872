//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import DateSpanPicker from "components/DateTimePicker/DateSpanPicker";
import WaitRipple from "components/WaitRipple";

import { userDuck } from "../UserDuck";

import classes from "../user.module.scss";
import AsGraph from "./AsGraph";
import AsTable from "./AsTable";
import { useI18n } from "components/lni18n";

const UserOnlineActivities = ({ userData }) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { sessionData } = useTypedSelector(state => state.user);
    const [asTable, setAsTable] = useState(false);

    if (sessionData == null) {
        return <WaitRipple />
    }

    const handleDatePickerChange = (dates) => {

        userDuck.getSessionData(userData.Id, dates.from, dates.to, dispatch);
    }

    return (
        <div className="pt-4 mt-5">
            <div className="row mb-4">
                <div className="col-sm-12 d-flex flex-wrap">
                    <div className={["mr-5", classes.DatePickerWrapper].join(' ')}>
                        <DateSpanPicker showTimeSelect StartDate={new Date(sessionData.Start)} EndDate={new Date(sessionData.End)}

                            onChange={(dates) => handleDatePickerChange(dates)}
                            showIcon
                            showWeekNumbers
                            showMonthDropdown
                            showYearDropdown
                            renderDays="past"
                        />
                    </div>

                    <div className="ml-auto d-print-none">
                        {asTable && <button className="btn btn-primary" onClick={() => setAsTable(false)}>{t.getText("as.graph")}</button>}
                        {!asTable && <button className="btn btn-primary" onClick={() => setAsTable(true)}>{t.getText("as.table")}</button>}
                    </div>

                </div>
            </div>
            {!asTable && <AsGraph sessionData={sessionData} />}
            {asTable && <AsTable sessionData={sessionData} />}


        </div>
    )

}


export default UserOnlineActivities;

