//@ts-check
import React from "react";
import Draggable from "react-draggable";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoginDuck } from "features/login/LoginDuck";
import { useI18n } from "components/lni18n";
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";

const SettingsDialog = (props) => {

    const { languageService: t } = useI18n();
    const dispatch = useDispatch();

    const user = useTypedSelector(state => state.login.user);
    const settings = user.TimeTableSettings ?? {
        StartTime: 8,
        EndTime: 17,
        ShowWeekends: false
    };

    /**
     * @type { { show: boolean , onDismiss: ()=>void  }}
     */
    const { show, onDismiss } = props;

    const handleChange = (name, val) => {
        const data = { ...settings };
        data[name] = val;
        dispatch(LoginDuck.updateTimeTableSettings(data));
    }

    const getNumbers = (start, end) => {
        var foo = [];

        for (var i = start; i <= end; i++) {
            foo.push(i);
        }

        return foo;

    }


    return <Draggable handle=".modal-header" bounds={{ left: -1024, top: 0, right: 1024, bottom: 1024 }}>
        <Modal size="md" role="alertdialog" isOpen={show} toggle={onDismiss} backdrop={true}>
            <ModalHeader className="bg-light" toggle={onDismiss}>{t.getText("settings")}</ModalHeader>
            <ModalBody>

                <div className="p-3">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>{t.getText('start.time')}</label>
                                <select className="form-control" name="StartTime" defaultValue={settings.StartTime}
                                    onChange={(e) => handleChange(e.currentTarget.name, +e.currentTarget.value)}>
                                    {getNumbers(6, 11).map((u) => {
                                        return (
                                            <option key={u} value={u}>{u}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                                <label >{t.getText('end.time')}</label>
                                <select className="form-control" name="EndTime" defaultValue={settings.EndTime}
                                    onChange={(e) => handleChange(e.currentTarget.name, +e.currentTarget.value)}>
                                    {getNumbers(13, 21).map((u) => {
                                        return (
                                            <option key={u} value={u}>{u}</option>
                                        )
                                    })}
                                </select>
                            </div>


                        </div>

                        <div className="mt-3">
                            <div className="custom-control custom-checkbox mb-4" >
                                <input
                                    checked={settings.ShowWeekends}
                                    type="checkbox"
                                    name="ShowWeekends"
                                    className="custom-control-input"
                                    id={"weekends"}
                                    onChange={(e) => handleChange("ShowWeekends", e.currentTarget.checked)} />

                                <label
                                    className="custom-control-label pt-1 ml-4 pointer"
                                    htmlFor={"weekends"}>
                                    {t.getText("show.weekends")}
                                </label>
                            </div>
                        </div>


                    </div>

                </div>

            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-primary btn-small" onClick={() => onDismiss()}>{t.getText("close")}</Button>
            </ModalFooter>
        </Modal>
    </Draggable >



}

export default SettingsDialog;
