//@ts-check
import { StringFormat } from "utils/StringFormat";
import instance from "utils/axios";
import { endPoint } from "AppConstants";
import { addLanguages, addDictionary, setCurrentLang } from "./LangageDuck";
import { LoginDuck } from "features/login/LoginDuck";
import { flagUrls } from "AppConstants";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import 'dayjs/locale/en-gb';
import localeData from "dayjs/plugin/localeData";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';


dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);

/**
 * @type import('./Language').LanguageService
 */
export class LanguageService {

    dictSelector = () => { return {} };

    /**
     * @type{()=> import('./Language').LanguageDto}
     */
    currentLangSelector = () => {
        return {
            Id: 0,
            Name: "sv-SE",
            DisplayName: "svenska",
            Short: "sv",
            flag: null,
            IsCurrent: true,
            StaticText: "sv"
        }
    };
    /**
     * @type{()=> import('./Language').LanguageDto[]}
     */
    languagesSelector = () => { return [] };
    locale = "en";



    handleChange = () => {
        if (this.currentLanguage && this.locale === this.currentLanguage.Short) {
            return;
        }
        if (this.currentLanguage) {
            this.locale = this.currentLanguage.Short;
            const locale = this.currentLanguage.Short === "en" ? this.currentLanguage.Name : this.currentLanguage.Short; 
            dayjs.locale(locale);
        }
    }

    registerStore = store => {
        this.dictSelector = () => store.getState().text.text;
        this.currentLangSelector = () => store.getState().text.currentLang;
        this.languagesSelector = () => store.getState().text.languages;

        store.subscribe(this.handleChange);
        const lang = this.currentLangSelector();
        if (lang) {
            this.locale = lang.Short;
        }
    }

    haveKey = (key) => {
        return !!this.currentDictionary[key];
    }

    getText = (key, ...ags) => {

        if (key && this.currentDictionary && this.currentDictionary[key]) {
            return StringFormat(this.currentDictionary[key], ags);
        }
        if (process.env.NODE_ENV !== "production") {
            console.error(`missing text key: ${key}`)
        }

        return key || "";
    };

    getTextFirstToUpper = (key, ...ags) => {
        /**
         * @type {string}
         */
        const s = this.getText(key, ags);
        if (!s) {
            return "";
        }

        return s.substr(0, 1).toUpperCase() + s.substr(1);
    };

    localeUtils = {
        formatDay: (d) => {
            this.getFullDateString(d);
        },
        formatMonthTitle: (d) => {
            dayjs(d).format("MMMM");
        },
        formatWeekdayShort: (d) => {
            dayjs(d).format("dd");
        },
        formatWeekdayLong: (d) => {
            dayjs(d).format("dddd");
        },
        getFirstDayOfWeek: (d) => {
            // @ts-ignore
            dayjs.localeData().firstDayOfWeek();
        }
    };



    // @ts-ignore
    get currentLanguage() {
        return this.currentLangSelector();
    }

    // @ts-ignore
    get currentDictionary() {
        return this.dictSelector();
    }

    // @ts-ignore
    get languages() {
        return this.languagesSelector();
    }

    getShortDateString = (date) => {

        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('L');
    }

    getShortDateTimeString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('L LT');
    }

    getTimeString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('LT');
    }

    getFullDateTimeString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('LLL');

    }

    getFullDateString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('LL');
    }

    getMediumDateString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('ll');
    }

    getMediumDateTimeString = (date) => {
        const djs = this.makeValidDayJs(date);
        if( !djs ){return null;}
        return djs.format('lll');
    }

    getTimeSpanString = (from, to, format) => {

        const start = dayjs(from);
        const end = dayjs(to);
        if (start.isSame(end, "date")) {
            const f = format.replace("HH:mm", "");
            return `${start.format(f)}  ${start.format("HH:mm")} - ${end.format("HH:mm")}`;
        }
        else {
            return `${start.format(format)} - ${end.format(format)}`;
        }

    }

    gethhmm = (s) => {
        let secs = Math.round(s);
        var minutes = Math.floor(secs / 60);
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        if (hours === 0) {
            return `00:${this.pad(minutes)}`;
        }
        return `${this.pad(hours)}:${this.pad(minutes)}`;
    }

    gethhmmss = (s) => {
        let secs = Math.round(s);
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        if (hours === 0) {
            return `${this.pad(minutes)}:${this.pad(secs)}`
        }
        return `${hours}:${this.pad(minutes)}:${this.pad(secs)}`;
    }

    /* private  */ pad(num) {
        return ("0" + num).slice(-2);
    }


    /* private  */ makeValidDayJs = (d) => {

        if (!d) {
            return null;
        }
        if (typeof d.getMonth === 'function') {
            return dayjs(d);
        }

        try {
            const dayj = dayjs(d);
            if (dayj.isValid()) {
                return dayj;
            }
        }
        catch {}

        return null;
    };

    localeUses24HourTime = (locale) => {
        locale = locale || this.locale;
        return parseInt(new Intl.DateTimeFormat(locale, {
            hour: 'numeric'
        }).formatToParts(new Date(2020, 0, 1, 15)).find(part => part.type === 'hour').value, 10) > 10;
    };

}


const getLanguages = (dispatch) => {
    let promise = new Promise((resolve, reject) => {
        instance.get(endPoint.LANGUAGES_URL)
            .then((response) => {
                if (response) {
                    let langs = response.data.map(l => {
                        return { ...l, flag: flagUrls[l.Name] }
                    });
                    dispatch(addLanguages(langs));

                    let current = response.data.find((i) => i.IsCurrent === true);
                    resolve(current.Id);
                }
                else {
                    resolve(0);
                }

            })
            .catch((error) => {
                reject(error);
            });
    });

    return promise;
}


const loadStrings = (langid, dispatch) => {

    let promise = new Promise((resolve, reject) => {

        instance.get(endPoint.GET_TEXT_DICTIONARY(langid))
            .then((response) => {
                if (response) {
                    dispatch(addDictionary(response.data));
                    dispatch(setCurrentLang(langid));
                }
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });

    return promise;
}

const changeLang = (id) => async dispatch => {
    await instance.put(endPoint.SET_LANG_URL, { Value: id });
    await Promise.all([loadStrings(id, dispatch), LoginDuck.loadLoginData(id, dispatch)])
    dispatch(setCurrentLang(id));

}


const languageService = new LanguageService();

export { getLanguages, loadStrings, changeLang };
export default languageService;