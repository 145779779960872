//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import { fileTestDuck } from "./FileTestDuck";
import FileUpload from "components/FileUpload/FileUpload";
import UploadedFileList from "./UploadedFileList";
import TestTop from "../test/TestTop";
import { Confirm, ModalConstants } from "components/OnlineModal";
import { courseDuck } from "features/course/CourseDuck";
import useTypedSelector from "utils/useTypedSelector";
import { TestResultStatus } from "../test/TestEnums";
import WaitRipple from "components/WaitRipple";
import LnIcon from "components/LnIcon";
import DebouncedButton from "components/DebounceButton";
import { Alert } from "reactstrap";


const FileTest = props => {

    const currentTheme = courseDuck.useCurrentTheme();
    const currentClass = useTypedSelector(state => state.classData.currentClass);

    const [showUploadWarning, setUploadWarning] = useState(false);

    const currentPart = courseDuck.useCurrentPart();
    const user = useTypedSelector(state => state.login.user);

    const currentTest = useTypedSelector(state => state.filetest.currentTest)
    const currentBaseTest = courseDuck.useCurrentTest();
    const dispatch = useDispatch();
    const { languageService: t } = useI18n();

    /**
     * 
     * @param {{key:string, name:string, size:number, type:string}} f 
     */
    const handleStudentUploadReady = (f) => {
        setUploadWarning(true);
        const data = {
            classId: currentClass.Id,
            testId: TestId,
            dto: {
                FileName: f.name,
                UploadedFileName: f.key,
                Type: fileTestDuck.FileTestFileTypeStrings.Submitted,
                UserId: user.Id
            }
        };

        dispatch(fileTestDuck.addFileToTest(data, false));
    }

    /**
     * @param {string} id 
     */
    const handleDelete = async (id) => {
        setUploadWarning(false);

        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("delete"),
            message: (<div>{t.getText("delete_general_confirm")}</div>),
            languageService: t,
            okOnEnter: true,
            type: "ok/cancel"
        });

        if (result === ModalConstants.Ok) {

            const data = {
                classId: currentClass.Id,
                testId: TestId,
                type: fileTestDuck.FileTestFileTypeStrings.Submitted,
                dto: {
                    FileId: id,
                    UserId: user.Id
                }
            }

            dispatch(fileTestDuck.removeFileFromTest(data, null));
        };
    }

    const setAsSubmitted = async () => {
        const result = await Confirm({
            buttons: [],
            backdrop: 'static',
            class: null,
            title: t.getText("submit"),
            message: (<div>{t.getText("wanttosaveanswerquestion")}</div>),
            languageService: t,
            okOnEnter: true,
            type: "yes/no"
        });

        if (result === ModalConstants.Yes) {
            dispatch(fileTestDuck.setAsSubmitted(TestId, currentClass.Id));
        }

        setUploadWarning(false);
    }


    if (!currentBaseTest || !currentTest || !currentTest.Options) {
        return <WaitRipple />
    }

    const { Status, TestId } = currentBaseTest;

    const studCanUploadMore = (Status === TestResultStatus.NotSet || Status === TestResultStatus.Passed || Status === TestResultStatus.TeacherUrge
        || Status === TestResultStatus.FollowUpNeeded || Status === TestResultStatus.Incomplete || Status === TestResultStatus.ApprovedByTeacherButCanbeBetter);


    return <div className={"filetest"}>

        <TestTop languageService={t} classId={currentClass.Id} themeId={currentTheme.Id} partId={currentPart.Id} />


        <div className="mt-3 max-col-width">
            <div >
                <div className="alert alert-grey" >
                    <div>
                        {currentBaseTest.StudentInstructions
                            && <div className="alert border-primary"
                                dangerouslySetInnerHTML={{ __html: currentBaseTest.StudentInstructions }} >
                            </div>}

                        <div className="max-col-width  clearfix" dangerouslySetInnerHTML={{ __html: currentTest.Question }}></div>
                    </div>
                </div>

                {currentBaseTest.Status === TestResultStatus.Incomplete && currentTest.SubmittedFiles && currentTest.SubmittedFiles.length > 0 &&
                    <div className="d-flex align-items-center mb-3 ">
                        <DebouncedButton onClick={setAsSubmitted} className="btn btn-primary">{t.getText("submit")}</DebouncedButton>
                        <LnIcon name="alert" className="icon-medium ml-3" /> <strong className="ml-2">{t.getText("click.here.to.submit.test")}</strong>
                    </div>
                }
                {studCanUploadMore &&
                    (<div className="position-relative">

                        <FileUpload handleUploadReady={handleStudentUploadReady} accept={currentTest.Options.fileExtensions} languageService={t} maxsize={currentTest.Options.maxsize} >
                            <div className="font-weight-bold">{t.getText("upload.file")}</div>
                            {currentTest.SubmittedFiles && (currentTest.SubmittedFiles.length > 0) && <div>{t.getText("filetest.replacefile.v2")}</div>}
                            <Alert className="mt-3 preserve-white" color="danger" toggle={()=>setUploadWarning(false)} isOpen={showUploadWarning} >
                                {t.getText("check.testfile.correct")}
                            </Alert>
                        </FileUpload>
                    </div>)}
            </div>
        </div>

        <div className="row mt-4 max-col-width">
            <div className="col-md-6 mb-4">
                <UploadedFileList canDelete={true} className="mt-3" header={t.getText("submitted")} handleDelete={handleDelete} languageService={t} files={currentTest.SubmittedFiles} />
            </div>

            <div className="col-md-6">
                <UploadedFileList canDelete={false} className="mt-3" header={t.getText("commented")} handleDelete={handleDelete} languageService={t} files={currentTest.CommentedFiles} />
            </div>
        </div>


        <div className="mt-4">&nbsp;</div>

    </div>

}


export default FileTest;

