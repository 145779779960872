//@ts-check
import React, { useRef, useEffect } from "react";
import { useStore } from "react-redux";
import ReactPlayer from 'react-player/file'
import { useDispatch } from "react-redux";
import useTypedSelector from "utils/useTypedSelector";
import { lectureDuck } from "features/course/part/Lectures/LectureDuck";
import lecturePlayerService from "./LecturePlayerService";
import { useCallback } from "react";
import { useState } from "react";

/**
 * 
 * @param {{ className: string,  onReady: Function  }} props 
 */
const VideoPlayer = ({ className, onReady }) => {

    const store = useStore();
    const { volume, autoNext, autoRepeat, doPlay, playbackRate, currentScreenData, currentScreen }
        = useTypedSelector(state => state.lecture);
    const dispatch = useDispatch();

    const [running, setRunning] = useState(false);

    /**
     * @type {{current: any }}
     */
    const mediaElement = useRef(null);

    const getPlayerTime = useCallback(() => {
        if (mediaElement && mediaElement.current) {
            const time = mediaElement.current.getCurrentTime();
            if (time != null) {
                return time;
            }
        }
        return 0;
    }, []);

    const setMediaPos = useCallback((fraction) => {
        if (mediaElement && mediaElement.current) {
            mediaElement.current.seekTo(fraction);
            dispatch(lectureDuck.setScreenTime(fraction * currentScreen.DurationInSeconds));
        }
    }, [currentScreen, dispatch, mediaElement]);


    const handleProgress = useCallback((e) => {
        dispatch(lectureDuck.setScreenTime(e.playedSeconds));
        lectureDuck.handleSegment(dispatch, store.getState, e.playedSeconds);
        lecturePlayerService.mediaPositionChanged.bind(lecturePlayerService)(e.playedSeconds, currentScreen, running);

    }, [currentScreen, dispatch, store.getState, running]);

    const handleMediaAtEnd = async () => {
        await handleMediaPause();
        if (autoNext) {
            window.setTimeout(() => lectureDuck.tryShiftScreen(dispatch, store.getState, lectureDuck.screenJumps.next, null), 200);
        }
        if (autoRepeat) {
            seekTo(0, "Video");
            dispatch(lectureDuck.setWantedPlayingState(true));
        }
    }

    const handleMediaPause = async () => {
        setRunning(false);
        await dispatch(lectureDuck.setPlayerRunningState(false));
        await dispatch(lectureDuck.setWantedPlayingState(false));
        await lectureDuck.onStop(dispatch, store.getState);
        const time = getPlayerTime();
        if (time) {
            lectureDuck.handleSegment(dispatch, store.getState, time);
        }

        // open up cue point if we stopped because of that.
        lecturePlayerService.mediaStopped.bind(lecturePlayerService)(currentScreen);

        await lectureDuck.SaveSegments(dispatch, store.getState, currentScreenData.Id);

    }

    const handlePlay = () => {
        setRunning(true);
        dispatch(lectureDuck.setPlayerRunningState(true));
    }

    const onSeek = (time) => {
        dispatch(lectureDuck.setRunningSegment({ start: time, end: time }));
    }

    const seekTo = async (fraction, screenType) => {
        if (screenType !== "Video") {
            return;
        }

        const { currentScreen } = store.getState().lecture;

        const time = getPlayerTime();
        let preventSeek = undefined;
        if (time) {
            dispatch(lectureDuck.setScreenTime(time));
            await lectureDuck.handleSegment(dispatch, store.getState, time);
            await lectureDuck.onStop(dispatch, store.getState);
            preventSeek = lecturePlayerService.mediaPositionChanged(fraction * currentScreen.DurationInSeconds, currentScreen, running);



            if (!preventSeek) {
                setMediaPos(fraction);
                await lectureDuck.handleSegment(dispatch, store.getState, time);
                dispatch(lectureDuck.setStoppedAtCuePoint(false));
            }
            else {
                setMediaPos(preventSeek);
            }

        }

    };

    useEffect(() => {
        lecturePlayerService.RegisterScreen({ seekTo: seekTo });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <div className={className + " w-100 h-100 d-flex align-items-center " + (currentScreenData.screenType !== "Video" ? "off-screen" : "")}>

        <ReactPlayer
            onEnded={handleMediaAtEnd}
            width="100%"
            height="100%"
            onSeek={onSeek}
            onPause={handleMediaPause}
            onPlay={handlePlay}
            onProgress={handleProgress} progressInterval={100}
            playing={doPlay}
            ref={mediaElement}
            controls={false}
            className={"ml-auto mr-auto"}
            volume={volume / 100}
            url={currentScreenData.videoUrl}
            pip={false}
            playbackRate={playbackRate}
            config={
                {
                    forceVideo: true,
                    attributes: {
                        controlsList: "nodownload",
                        playsInline: true,
                        autoPlay: true
                    },
                    tracks: []

                }}
        />
    </div>
}


export default VideoPlayer;