//@ts-check

import React from "react";
import ScrollContent from 'components/ScrollContent';
import useTypedSelector from "utils/useTypedSelector";
import AboutCourseWidget from './AboutCourseWidget';
import CourseAccessWidget from './CourseAccessWidget';
import EventsUpdateWidget from './EventsUpdateWidget';
import NotificationsWidget from './NotificationsWidget';
import VerifyEmail2Widget from './VerifyEmail2Widget';
import ResultsWidget from './ResultsWidget';
import TimeTableWidget from './TimeTableWidget';
import SponsorsWidget from './SponsorsWidget';
import classes from "./dashboard.module.scss";
import FeedbackWidget from "./FeedbackWidget";
import TeacherResultsWidget from "./TeacherResultsWidget";
import { ClientMode } from "../ClassesDuck";
import SelfStudyCourseAccessWidget from "./SelfStudyCourseAccessWidget";
import SocMedWidget from "./SocMedWidget";

/**
 * Dashboard
 * @module features/classes/Dashboard/Dashboard
 * @return a wrapper for available Dashboard widgets.
 */
const Dashboard = () => {

    const { currentClass } = useTypedSelector(state => state.classData);
    const user = useTypedSelector(state => state.login.user);

    let isTeacher = false;

    /**
    * @type {import("../Classes").StudentClassDtoForTeacher}
    */
    let teacherData = null;

    let courseType = ClientMode.student;

    /**
     * @type {import("../Classes").StudentClassDtoForStudent}
     */
    let dashBoardData = currentClass.DashBoardData.TeacherData;
    if (!dashBoardData) {
        dashBoardData = currentClass.DashBoardData.StudentData;
        courseType = dashBoardData.CourseType;
    }
    else {
        isTeacher = true;
        teacherData = currentClass.DashBoardData.TeacherData;

    }

    const comingEvents = currentClass.DashBoardData.ComingEvents;

    /* currentClass.DashBoardData
        TeacherData: null
        StudentData: {}
        OnlyShowUnit: false
        UnitName: "LäraNära"
        Start: "2019-01-01T00:00:00"

    */

    let key = 0;


    const availableWidgets = {
        ProgressWidget: () => isTeacher ? <TeacherResultsWidget data={teacherData} /> : <ResultsWidget data={dashBoardData} />,
        MessagesWidget: () => <NotificationsWidget classId={currentClass.Id} notifications={currentClass.notifications} CanEdit={currentClass.CanEditNotifications} />,
        TimeTableWidget: () => <TimeTableWidget ComingEvents={comingEvents} StudentClass={dashBoardData.StudentClass} />,
        CourseAccessWidget: () => <CourseAccessWidget isTeacher={isTeacher} StudentData={dashBoardData} DivHeight={'half'} />,
        EventsUpdateWidget: () => <EventsUpdateWidget StudentData={dashBoardData} />,
        AboutCourseWidget: () => <AboutCourseWidget Data={dashBoardData} />,
        SponsorsWidget: () => <SponsorsWidget Data={dashBoardData} />,
        SocMedWidget: () => <SocMedWidget DivHeight="half" />,
        SelfStudyCourseAccessWidget: () => <SelfStudyCourseAccessWidget StudentData={dashBoardData} DivHeight={'half'} />
    };
    let shownWidgets = [];

    if (user && user.Email2 && user.Mail2Verified === false) {
        shownWidgets.push(<VerifyEmail2Widget />);
    }



    shownWidgets.push(availableWidgets.ProgressWidget());
    shownWidgets.push(availableWidgets.MessagesWidget());

    if (courseType === ClientMode.student) {
        shownWidgets.push(availableWidgets.EventsUpdateWidget());
    }


    let accessWidget = null;
    if (courseType !== ClientMode.selfstudies) {
        accessWidget = availableWidgets.CourseAccessWidget;
    }
    else {
        accessWidget = availableWidgets.SelfStudyCourseAccessWidget;
    }

    shownWidgets.push(<div className="d-flex flex-column justify-content-between">
        {accessWidget()}
        {availableWidgets.SocMedWidget()}
    </div>);


   
    //    shownWidgets.push(availableWidgets.SocMedWidget());

    shownWidgets.push(<FeedbackWidget data={currentClass.FeedbackData} />);

    if (dashBoardData.StudentClass.CourseDescription && dashBoardData.StudentClass.CourseDescription.trim() !== "") {
        shownWidgets.push(availableWidgets.AboutCourseWidget());
    }

    if (courseType !== ClientMode.selfstudies && comingEvents && comingEvents.length > 0) {
        shownWidgets.push(availableWidgets.TimeTableWidget());
    }



    if (dashBoardData.Addons && dashBoardData.Addons.length > 0) {
        shownWidgets.push(availableWidgets.SponsorsWidget());
    }



    return (
        <ScrollContent scrollname="class-dashboard" >

            <div className={classes.dashboard_container}>
                {shownWidgets.map((widget) => {
                    return (
                        <div className="" key={key++}>
                            {widget}
                        </div>
                    )
                })}

            </div>
        </ScrollContent>
    )
}

export default Dashboard;
