//@ts-check

import React, { lazy, Suspense, useState } from "react";
import ScrollContent from 'components/ScrollContent';
import WaitRipple from "components/WaitRipple";
import SettingsDialog from "./SettingsDialog";


const TimeTableLoader = () => {

   
    const [showSettings, setShowsettings] = useState(false);

    const LazyTable = lazy(() => import("./TimeTable"));
    return <Suspense fallback={<ScrollContent scrollname="class-timetable">
        <div className="card-box big-card-box">
            <WaitRipple />
        </div>
    </ScrollContent>}>
        <>
            <LazyTable onSettings={()=>setShowsettings(true)}  />
            <SettingsDialog  show={showSettings} onDismiss={()=>setShowsettings(false)} />
        </>
    </Suspense>
}


export default TimeTableLoader;
